import React, {createContext, useReducer} from 'react'
// navbar state//reducer
import {activeNavReducer} from '../reducers/activeNavReducer'
import {initialState} from '../reducers/activeNavReducer'
//navbar state context
export const ActiveNavStateContext=createContext()
export const ActiveNavDispatchContext=createContext()

//this is where the GLOBAL CONTEXT is DEFINED and passed down
//it is best to split up state and dispatch for performance reasonsPropTypes.
//less re renders if only one changes. sometimes only state needed etc. 
export const GlobalContextProvider=({children})=>{
    const [state, dispatch] = useReducer(activeNavReducer, initialState);
    return (
        <ActiveNavDispatchContext.Provider value={dispatch}>
            <ActiveNavStateContext.Provider value={state}>
                {children}
            </ActiveNavStateContext.Provider>
        </ActiveNavDispatchContext.Provider>
    )
}