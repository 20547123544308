exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-mdx": () => import("./../../../src/pages/about-us/index.mdx" /* webpackChunkName: "component---src-pages-about-us-index-mdx" */),
  "component---src-pages-about-us-meet-dr-michael-kunkel-mdx": () => import("./../../../src/pages/about-us/meet-dr-michael-kunkel.mdx" /* webpackChunkName: "component---src-pages-about-us-meet-dr-michael-kunkel-mdx" */),
  "component---src-pages-about-us-meet-dr-trent-listello-js": () => import("./../../../src/pages/about-us/meet-dr-trent-listello.js" /* webpackChunkName: "component---src-pages-about-us-meet-dr-trent-listello-js" */),
  "component---src-pages-about-us-our-team-js": () => import("./../../../src/pages/about-us/our-team.js" /* webpackChunkName: "component---src-pages-about-us-our-team-js" */),
  "component---src-pages-about-us-referral-js": () => import("./../../../src/pages/about-us/referral.js" /* webpackChunkName: "component---src-pages-about-us-referral-js" */),
  "component---src-pages-about-us-testimonials-js": () => import("./../../../src/pages/about-us/testimonials.js" /* webpackChunkName: "component---src-pages-about-us-testimonials-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-center-3-d-ct-scan-js": () => import("./../../../src/pages/patient-center/3d-ct-scan.js" /* webpackChunkName: "component---src-pages-patient-center-3-d-ct-scan-js" */),
  "component---src-pages-patient-center-anesthesia-js": () => import("./../../../src/pages/patient-center/anesthesia.js" /* webpackChunkName: "component---src-pages-patient-center-anesthesia-js" */),
  "component---src-pages-patient-center-finance-insurance-mdx": () => import("./../../../src/pages/patient-center/finance-insurance.mdx" /* webpackChunkName: "component---src-pages-patient-center-finance-insurance-mdx" */),
  "component---src-pages-patient-center-initial-consultation-mdx": () => import("./../../../src/pages/patient-center/initial-consultation.mdx" /* webpackChunkName: "component---src-pages-patient-center-initial-consultation-mdx" */),
  "component---src-pages-patient-center-patient-forms-js": () => import("./../../../src/pages/patient-center/patient-forms.js" /* webpackChunkName: "component---src-pages-patient-center-patient-forms-js" */),
  "component---src-pages-patient-center-post-procedure-mdx": () => import("./../../../src/pages/patient-center/post-procedure.mdx" /* webpackChunkName: "component---src-pages-patient-center-post-procedure-mdx" */),
  "component---src-pages-patient-center-pre-procedure-mdx": () => import("./../../../src/pages/patient-center/pre-procedure.mdx" /* webpackChunkName: "component---src-pages-patient-center-pre-procedure-mdx" */),
  "component---src-pages-patient-center-registration-js": () => import("./../../../src/pages/patient-center/registration.js" /* webpackChunkName: "component---src-pages-patient-center-registration-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-safety-measures-js": () => import("./../../../src/pages/safety-measures.js" /* webpackChunkName: "component---src-pages-safety-measures-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-yomi-js": () => import("./../../../src/pages/yomi.js" /* webpackChunkName: "component---src-pages-yomi-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */)
}

