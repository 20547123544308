export const initialState={
    active:false,
    secondList:false,
    currentLink:null,
    items:[]
}

export const activeNavReducer=(state, action)=> { 
    switch (action.type) {
      case 'SET_ACTIVE':
        return {
            ...state, 
            active:!state.active,
            secondList:false
        }
      case 'LINK_CLICKED':
          return {
            active:true,
            secondList:true,
            currentLink:action.payload.currentLink,
            items:action.payload.items
        }
        case 'PLUS_CLICKED':
          return {
            active:true,
            secondList:false
        }
      case 'INIT_STATE':
        return initialState
  default:
    throw new Error('ERROR - Refer to Reducer')
}   
}
